export default {
    'Loading...': 'Yuklanmoqda...',
    'Booking an appointment': 'Qabulga yozilish',
    'Online registration is disabled': 'Online-qabul faol emas',
    'Select service': 'Xizmatni tanlang',
    'Confirm': 'Tasdiqlash',
    'Enter the phone number provided by registering at the clinic': 'Klinikada r\'oyxatdan o\'tgan telefon raqamingizni kiriting',
    'Enter Phone Number': 'Telefon raqamini kiriting',
    'Send': 'Yuborish',
    'Verification Code': 'Tasdiqlash kodi',
    'verification-message': '{phone} raqamiga tasdiqlash kodi yuborildi. Tasdiqlash kodni kiriting.',
    'Invalid code': 'Noto\'g\'ri kod',
    'Wrong Number?': 'Noto\'g\'ri raqam?',
    'Verifying...': 'Tasdiqlanmoqda...',
    'Verify': 'Tasdiqlash',
    'Name': 'Ism',
    'Enter Name': 'Ismingizni kiriting',
    'Surname': 'Familiya',
    'Enter Surname': 'Familiyangizni kiriting',
    'Saving...': 'Saqlanmoqda...',
    'Save': 'Saqlash',
    'We will contact you shortly and confirm the appointment.': 'Siz bilan tez orada aloqa chiqamiz va so\'rovingizni tasdiqlaymiz.',
    'No free time available': 'Bu kun bo\'sh vaqt mavjud emas',
    'Back to Home': 'Boshiga qaytish',
    'No upcoming appointments': 'Bo\'lajak qabullar mavjud emas',
    'Select clinic': 'Klinikani tanlang',
    'You are registered in these clinics': 'Siz quydagi klinikalarda ro\'yxatdan o\'tgansiz',
    'No clinics': 'Klinikalar topilmadi',
    'Home': 'Asosiy',
    'Appointments': 'Qabullar',
    'Contacts': 'Kontaktlar',
    'Switch clinic': 'Boshqa klinikaga o\'tish',
    'Logout': 'Chiqish',
    'Log in to your personal cabinet': 'Shaxsiy kabinetga kirish',
    'Log In': 'Kirish',
    'Book an appointment': 'Qabulga yozilish',
    'Upcoming appointments': 'Kelgusi qabullar',
    'Show all': 'Hammasini ko\'rish',
    'Not confirmed': 'Tasdiqlanmagan',
    'Confirmed': 'Tasdiqlangan',
    'Cancelled': 'Bekor qilingan',
    'Completed': 'Yakunlangan',
    'Not shown': 'Kelmadi',
    'Started': 'Boshlandi',

    'Please, enter valid phone number': 'Iltimos, telefon raqamini to\'g\'ri kiriting',
    'Enter code from SMS': 'SMSdagi kodni kiriting',

    'Select a specialist': 'Mutaxassis tanlang',
    'No specialists available': 'Mutaxassis topilmadi',
    'Select a day': 'Kunni tanlang',
    'Select a time': 'Vaqtni tanlang',
    'Input data to register': 'Ro\'yxatdan o\'tish uchun ma\'lumotlarni kiriting',
    'Enter your name': 'Ismingizni kiriting',
    'Enter your phone number': 'Telefon raqamigizni kiriting',
    'Phone number': 'Telefon raqami',
}
