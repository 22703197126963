export default {
    'Loading...': 'Ladataan...',
    'Booking an appointment': 'Nimittäminen',
    'Online registration is disabled': 'Online-varaukset pois käytöstä',
    'Select service': 'Valitse palvelu',
    'Confirm': 'Vahvistaa',
    'Enter the phone number provided by registering at the clinic': 'Syötä puhelinnumero, jonka määritit rekisteröityessäsi klinikalle',
    'Enter Phone Number': 'Anna puhelinnumero',
    'Send': 'Lähettää',
    'Verification Code': 'Vahvistuskoodi',
    'verification-message': 'Vahvistuskoodin sisältävä viesti on lähetetty numeroon {phone}. Syötä koodi jatkaaksesi.',
    'Invalid code': 'Virheellinen koodi',
    'Wrong Number?': 'Väärä numero?',
    'Verifying...': 'Tutkimus...',
    'Verify': 'Vahvistaa',
    'Name': 'Nimi',
    'Enter Name': 'Kirjoita nimesi',
    'Surname': 'Sukunimi',
    'Enter Surname': 'Kirjoita sukunimi',
    'Saving...': 'Säilytys...',
    'Save': 'Tallentaa',
    'We will contact you shortly and confirm the appointment.': 'Otamme sinuun yhteyttä pian ja vahvistamme varauksesi.',
    'No free time available': 'Ei vapaa-aikaa tänä päivänä',
    'Back to Home': 'Takaisin kotiin',
    'No upcoming appointments': 'Ei tulevia tapaamisia',
    'Select clinic': 'Valitse klinikka',
    'You are registered in these clinics': 'Olet rekisteröitynyt seuraaville klinikoille',
    'No clinics': 'Ei klinikoita',
    'Home': 'Aloitusnäyttö',
    'Appointments': 'Tapaamiset',
    'Contacts': 'Yhteystiedot',
    'Switch clinic': 'Vaihda klinikka',
    'Logout': 'Kirjautua ulos',
    'Log in to your personal cabinet': 'Kirjaudu sisään henkilökohtaiselle tilillesi',
    'Log In': 'Kirjaudu sisään',
    'Book an appointment': 'Varata tapaaminen',
    'Upcoming appointments': 'Tulevia tapaamisia',
    'Show all': 'Näytä kaikki',
    'Not confirmed': 'Ei vahvistettu',
    'Confirmed': 'Vahvistettu',
    'Cancelled': 'Peruutettu',
    'Completed': 'Valmis',
    'Not shown': 'Ei näytetty',
    'Started': 'Aloitettu',

    'Please, enter valid phone number': 'Ole hyvä ja anna kelvollinen puhelinnumero',
    'Enter code from SMS': 'Syötä koodi tekstiviestistä',

    'Select a specialist': 'Valitse asiantuntija',
    'No specialists available': 'Ei erikoisasiantuntijoita saatavilla',
    'Select a day': 'Valitse päivä',
    'Select a time': 'Valitse aika',
    'Input data to register': 'Syötä tiedot rekisteröintiä varten',
    'Enter your name': 'Kirjoita nimesi',
    'Enter your phone number': 'Anna puhelinnumerosi',
    'Phone number': 'Puhelinnumero',
}
