export default {
    'Loading...': 'Загрузка...',
    'Booking an appointment': 'Запись на приём',
    'Online registration is disabled': 'Онлайн-записи отключены',
    'Select service': 'Выберите услугу',
    'Confirm': 'Подтвердить',
    'Enter the phone number provided by registering at the clinic': 'Введите номер телефона, указанный при регистрации в клинике',
    'Enter Phone Number': 'Введите номер телефона',
    'Send': 'Отправить',
    'Verification Code': 'Код подтверждения',
    'verification-message': 'Сообщение с кодом подтверждения отправлено на {phone}. Введите код для продолжения.',
    'Invalid code': 'Неверный код',
    'Wrong Number?': 'Неправильный номер?',
    'Verifying...': 'Проверка...',
    'Verify': 'Подтвердить',
    'Name': 'Имя',
    'Enter Name': 'Введите имя',
    'Surname': 'Фамилия',
    'Enter Surname': 'Введите фамилию',
    'Saving...': 'Сохранение...',
    'Save': 'Сохранить',
    'We will contact you shortly and confirm the appointment.': 'Мы свяжемся с вами в ближайшее время и подтвердим вашу запись на приём.',
    'No free time available': 'Нет свободного времени в этот день',
    'Back to Home': 'Вернуться на Главную',
    'No upcoming appointments': 'Нет предстоящих приёмов',
    'Select clinic': 'Выберите клинику',
    'You are registered in these clinics': 'Вы зарегистрированы в следующих клиниках',
    'No clinics': 'Нет клиник',
    'Home': 'Главная',
    'Appointments': 'Записи на приём',
    'Contacts': 'Контакты',
    'Switch clinic': 'Сменить клинику',
    'Logout': 'Выйти',
    'Log in to your personal cabinet': 'Войдите в личный кабинет',
    'Log In': 'Войти',
    'Book an appointment': 'Записаться на приём',
    'Upcoming appointments': 'Предстоящие встречи',
    'Show all': 'Показать все',
    'Not confirmed': 'Не подтверждён',
    'Confirmed': 'Подтверждён',
    'Cancelled': 'Отменен',
    'Completed': 'Завершен',
    'Not shown': 'Не явился',
    'Started': 'На приёме',

    'Please, enter valid phone number': 'Введен неверный формат номера телефона. Пожалуйста, попробуйте снова',
    'Enter code from SMS': 'Введите код из SMS',

    'Enter answer here': 'Введите ответ',
    'Previous': 'Предыдущий',
    'Submit': 'Отправить',
    'Next': 'Следующий',
    'Thank you': 'Спасибо вам',
    'for your feedback!': 'за отзыв!',
    'Give feedback': 'Оставить отзыв',

    'Checking': 'Проверка',
    'Error': 'Ошибка',

    'Select a specialist': 'Выберите специалиста',
    'No specialists available': 'Нет доступных стоматологов',
    'Select a day': 'Выберите день',
    'Select a time': 'Выберите время',
    'Input data to register': 'Введите данные для регистрации',
    'Enter your name': 'Введите ваше имя',
    'Enter your phone number': 'Введите ваш номер телефона',
    'Phone number': 'Номер телефона',
}
